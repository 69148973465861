import React from "react"
import css from "./SkwatArchivedListItem.module.scss"

function SkwatArchivedListItem({ title, imgSrc, href }) {
  return (
    <div className={css.root}>
      <a href={href}>
        <img src={imgSrc} alt={``} loading="lazy" />
        <h3>{title}</h3>
      </a>
    </div>
  )
}

export default SkwatArchivedListItem
