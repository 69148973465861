import React from "react"
import css from "./SkwatCurrentListItem.module.scss"

const nl2br = str => {
  return str.replace(/[\n]/g, "<br />")
}

function SkwatCurrentListItem({ title, location, date, imgSrc, href }) {
  console.log(location)

  return (
    <div className={css.root}>
      <a href={href}>
        {imgSrc && <img src={imgSrc} alt={``} loading="lazy" />}
        <div className={css.info}>
          <h3>{title}</h3>

          {/*{location && (*/}
          {/*  <p dangerouslySetInnerHTML={{ __html: nl2br(location) }}></p>*/}
          {/*)}*/}
        </div>
        {/*<p className={css.date}>{date}</p>*/}
      </a>
    </div>
  )
}

export default SkwatCurrentListItem
