import React, { Component } from "react"
import { graphql } from "gatsby"
import css from "./styles/index.module.scss"
import Seo from "../components/Seo"
import CommonHeader from "../components/CommonHeader"
import SkwatCurrentListItem from "../components/index/SkwatCurrentListItem"
import SkwatArchivedListItem from "../components/index/SkwatArchivedListItem"

class Index extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = {
      isSpMode: null,
      isMenuOpen: false,
    }
    this._spChecker = React.createRef()
  }

  /**
   *
   */
  componentDidMount() {
    if (typeof window !== "undefined") {
      this.handleWindowResize = e => {
        this.doResize()
      }
      window.addEventListener("resize", this.handleWindowResize, true)
    }
    setTimeout(() => {
      this.doResize()
    }, 1)
  }

  /**
   *
   */
  doResize() {
    if (window) {
      try {
        const isSpMode =
          document.defaultView.getComputedStyle(this._spChecker.current, null)
            .display !== `none`

        this.setState(state => ({
          isSpMode: isSpMode,
        }))
      } catch (e) {}
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    let baseStyle = {}
    if (this.state.isSpMode) {
      baseStyle.height = document.documentElement.clientHeight + `px`
    }

    let largePosts = []
    let normalPosts = []

    // SKWAT
    let skwatPosts = this.props.data.allWpSkwat.edges
    for (let i = 0; i < skwatPosts.length; i++) {
      let post = skwatPosts[i].node

      if (post.acfSkwat.isclosed) {
        normalPosts.push(
          <div className={css.currentListGroup__item}>
            <SkwatArchivedListItem
              title={post.title}
              imgSrc={
                post.featuredImage
                  ? post.featuredImage.node.localFile.childImageSharp.fixed.srcWebp
                  : null
              }
              href={`/${post.slug.toUpperCase()}`} // gatsby-node.jsで大文字に変換しているので、ここでは大文字にする
            />
          </div>
        )
      } else {
        largePosts.push(
          <div className={css.currentListGroup__item}>
            <SkwatCurrentListItem
              title={post.title}
              location={post.acfSkwat.tenantData.location}
              date={post.acfSkwat.schedule.date}
              imgSrc={
                post.featuredImage
                  ? post.featuredImage.node.localFile.childImageSharp.fixed.srcWebp
                  : null
              }
              href={`/${post.slug.toUpperCase()}`} // gatsby-node.jsで大文字に変換しているので、ここでは大文字にする
            />
          </div>
        )
      }
    }

    return (
      <div className={css.base} style={baseStyle}>
        <Seo lang={`en`} />
        <CommonHeader showInstaButton={true} showMenu={true} />
        <div className={css.currentListGroup}>
          <h2 className={css.currentListGroup__heading}>Current</h2>

          <div className={css.currentListGroup__body}>{largePosts}</div>
        </div>
        <div className={css.archivedListGroup}>
          <h2 className={css.archivedListGroup__heading}>Archive</h2>

          <div className={css.archivedListGroup__body}>{normalPosts}</div>

          <div className={`onlySp`} ref={this._spChecker} />
        </div>
      </div>
    )
  }
}

export default Index

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "VirtualBuilding" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            template
            draft
            map {
              lat
              lng
              text
              color
              pinImage
            }
          }
        }
      }
    }
    allWpSkwat {
      edges {
        node {
          slug
          title
          featuredImage {
            node {
              mediaItemUrl
              localFile {
                childImageSharp {
                  fixed(webpQuality: 90, width: 1024) {
                    ...GatsbyImageSharpFixed
                    srcWebp
                  }
                }
              }
            }
          }
          acfSkwat {
            isclosed
            schedule {
              date
            }
            tenantData {
              location
            }
            background {
              color
              image {
                mediaItemUrl
                caption
              }
            }
            pinImage {
              mediaItemUrl
              caption
            }
            tenantData {
              lat
              lng
            }
          }
          skwatTypes {
            nodes {
              name
              termTaxonomyId
            }
          }
        }
      }
    }
  }
`
